import { createStore as reduxCreateStore } from 'redux';
import {
  OPEN_MOBILE_MENU_OVERLAY,
  CLOSE_MOBILE_MENU_OVERLAY,
  WATCHED_WE_MAKE_THINGS_ANIMATION,
  OPEN_IMAGE_GALLERY_MODAL,
  CLOSE_IMAGE_GALLERY_MODAL,
} from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case OPEN_MOBILE_MENU_OVERLAY:
      return { ...state, showMobileMenuOverlay: true };
    case CLOSE_MOBILE_MENU_OVERLAY:
      return { ...state, showMobileMenuOverlay: false };
    case WATCHED_WE_MAKE_THINGS_ANIMATION:
      return { ...state, haveWatchedWeMakeThingsAnimation: true };
    case OPEN_IMAGE_GALLERY_MODAL:
      return { ...state, isImageGalleryModalOpen: true };
    case CLOSE_IMAGE_GALLERY_MODAL:
      return { ...state, isImageGalleryModalOpen: false };
    default:
      return state;
  }
};

const initialState = {
  showMobileMenuOverlay: false,
  haveWatchedWeMakeThingsAnimation: false,
  isImageGalleryModalOpen: false,
};

const createStore = () => reduxCreateStore(reducer, initialState);
export default createStore;
