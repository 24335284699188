// global styles
import 'reset-css/reset.css';
import '@styles/reset-plus.styl';
import '@styles/global.styl';

// wrap root element with providers
import wrapWithProviders from './wrap-with-providers';

export const wrapRootElement = wrapWithProviders;

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!('IntersectionObserver' in window)) { // eslint-disable-line no-undef
    require('intersection-observer'); // eslint-disable-line global-require
  }
};
