import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import createStore from './src/store/createStore';

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   const ReactRedux = require('react-redux'); // eslint-disable-line
//   console.log(whyDidYouRender);
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//     trackExtraHooks: [
//       [ReactRedux, 'useSelector'],
//     ],
//   });
// }

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore();
  return (
    <ReduxProvider store={store}>
      {element}
    </ReduxProvider>
  );
};
