export const OPEN_MOBILE_MENU_OVERLAY = 'OPEN_MOBILE_MENU_OVERLAY';
export const CLOSE_MOBILE_MENU_OVERLAY = 'CLOSE_MOBILE_MENU_OVERLAY';
export const WATCHED_WE_MAKE_THINGS_ANIMATION = 'WATCHED_WE_MAKE_THINGS_ANIMATION';
export const OPEN_IMAGE_GALLERY_MODAL = 'OPEN_IMAGE_GALLERY_MODAL';
export const CLOSE_IMAGE_GALLERY_MODAL = 'CLOSE_IMAGE_GALLERY_MODAL';

export const openMobileMenuOverlay = () => ({
  type: OPEN_MOBILE_MENU_OVERLAY,
});

export const closeMobileMenuOverlay = () => ({
  type: CLOSE_MOBILE_MENU_OVERLAY,
});

export const watchedWeMakeThingsAnimation = () => ({
  type: WATCHED_WE_MAKE_THINGS_ANIMATION,
});

export const openImageGalleryModal = () => ({
  type: OPEN_IMAGE_GALLERY_MODAL,
});

export const closeImageGalleryModal = () => ({
  type: CLOSE_IMAGE_GALLERY_MODAL,
});
